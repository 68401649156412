/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap'); 
  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.google.com/noto/specimen/Noto+Sans+Hebrew?preview.layout=grid&query=heb');
@import '~leaflet/dist/leaflet.css';
:root {
  --main-font-family2: 'Noto Sans Hebrew', sans-serif;
  touch-action: pan-x pan-y;
  height: 100%
}
body {
  background-color: #f5f8ff;
  // font-family: 'El Messiri';
  // font-family: 'Cairo';
  // font-family: 'Noto Sans Hebrew';
  font-family: var(--main-font-family2);

  color: black;
  font-weight: 700;
}
.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

td{
  font-size: 14px;
}

th{
  font-size: 14px;
}
.table i
{
    font-size: 16px;
}

.p-orderlist .p-orderlist-controls {
  display: none !important; /* إخفاء قسم الأزرار بالكامل */
}


@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

.p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td {
  padding: 5px 10px !important;
  text-align: start;
}

@media (max-width: 768px) {
  .p-datatable-scrollable .p-datatable-thead {
    max-width: 100vw;
  }
  .footer {
    padding-inline: 0;
  }

  .page-wrapper .page-header {
    position: static !important;
  }

  .page-wrapper .page-body-wrapper .page-title {
    margin-bottom: 10px;
  }
}

.p-menu.p-menu-overlay {
  position: absolute !important;
  display: block !important;
  top: 60px !important;
  left: auto !important;
  right: -10px;
}

html.rtl .p-menu.p-menu-overlay {
  right: auto !important;
  left: -10px !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  margin: 0 !important;
}
.language-switcher button.active {
  font-weight: bold;
  text-decoration: underline;
}